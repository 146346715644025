.sectionTitle {
    text-align: center;
    font-size: 30px !important;
    text-transform: uppercase;
    letter-spacing: 0.08em;
}

ul {
    list-style-type: disc;
    color: #333;
}

li {
    margin-top: -4px;
}

.header {
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.line {
    background: #6cacdc;
    margin: auto;
    width: 260px;
    height: 3px;
}

.line2 {
    background: #c55;
    margin: auto;
    width: 100%;
    height: 2px;
}

.button {
    cursor: pointer;
    transition: all ease-in 0.1s;
}

.button:hover{
    font-size: 1.8em;
    color: rgb(59, 121, 255)
}

.clickable {
    cursor: pointer;
}