.bannerContainer {
    display: flex;
    background: linear-gradient(#ffff, #fff1),
    center/cover no-repeat url('/images/banner.jpg');
    min-height: calc(100vh - 3px);
    color: #333;
}

.bannerTitle {
    font-size: 5vh;
    font-weight: 900;
    letter-spacing: 0.05em;
    line-height: 1em;
    text-transform: uppercase;
    font-family: 'Georgia';
}

/* 1, 4

-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Sans Sherif Pro"
    sans-serif; */