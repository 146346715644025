.projectItemDisplay {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border: solid 3px darkgray;
    border-radius: 20%;
    filter: grayscale(100%);
    transition: all linear 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #f5f5f5;
    cursor: pointer;
}

.projectItemDisplay:hover {
    filter:grayscale(0);
    border-radius: 10%;
    border-color: #daa;
}

.projectItemDisplay img {
    min-width: 100%;
}

.projectModalImg {
    min-width: 100%;    
}

.darkFont {
    color: #111;
}

.technologies {
    text-transform: uppercase;
    letter-spacing: 0.1em;
}