#footer {    
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    height: 40px;
}

.footer-block {
    margin: 9px 0;
}